const translations = {
  "homeBox1_title": {
    "en": "Exclusivity meets the future",
    "it": "L'esclusività incontra il futuro"
  },
  "homeBox1_subtitle": {
    "en": "More than just an account: it's CRH Money!",
    "it": "Più di un semplice conto: è CRH Money!"
  },
  "homeBox1_text1": {
    "en": "The security of your data and your money is our priority. With CRH Money you don't just access a service, but an exclusive world made of unique privileges and tailored benefits.",
    "it": "La sicurezza dei tuoi dati e del tuo denaro è la nostra priorità. Con CRH Money non accedi solo a un servizio, ma a un mondo esclusivo fatto di privilegi unici e vantaggi su misura."
  },
  "homeBox1_text2": {
    "en": "An experience that has its roots in a millennial history, where tradition and innovation meet to offer you the best.",
    "it": "Un'esperienza che affonda le sue radici in una storia millenaria, dove tradizione e innovazione si incontrano per offrirti il meglio."
  },
  "homeBox1_cta": {
    "en": "Discover today the value of being part of something extraordinary.",
    "it": "Scopri oggi il valore di far parte di qualcosa di straordinario."
  },
  "homeBox1_button": {
    "en": "OPEN YOUR ACCOUNT",
    "it": "APRI IL TUO CONTO"
  },
  "homeBox21": {
    "en": "An exclusive experience",
    "it": "Un'esperienza esclusiva"
  },
  "homeBox22": {
    "en": "of payment",
    "it": "di pagamento"
  },
  "homeBox23": {
    "en": "Use CRH Money, your payment APP",
    "it": "Usa CRH Money, la tua APP di pagamento"
  },
  "homeBox24": {
    "en": "for everyday financial management",
    "it": "per la gestione finanziaria di tutti i giorni"
  },
  "homeBox25": {
    "en": "Freedom",
    "it": "Libertà"
  },
  "homeBox26": {
    "en": "Manage your account without annual limits, wherever you are. Make payments with European and international transfers with total simplicity. Open up to 7 virtual cards, add them to your digital Wallet and pay or withdraw without barriers, anywhere in the world.",
    "it": "Gestisci il tuo conto senza limiti annuali, ovunque ti trovi. Effettua pagamenti con bonifici europei e internazionali in totale semplicità. Apri fino a 7 carte virtuali, aggiungile al tuo Wallet digitale e paga o preleva senza barriere, ovunque nel mondo."
  },
  "homeBox27": {
    "en": "Security",
    "it": "Sicurezza"
  },
  "homeBox28": {
    "en": "Your peace of mind is our priority. With CRH Money, your money is protected thanks to the most advanced computer technologies and rigorous security standards. We adopt the fund protection scheme in compliance with European Central Bank regulations, guaranteeing you maximum reliability and security.",
    "it": "La tua serenità è la nostra priorità. Con CRH Money, i tuoi soldi sono protetti grazie alle più avanzate tecnologie informatiche e a rigorosi standard di sicurezza. Adottiamo lo schema di protezione dei fondi in conformità con le normative della Banca Centrale Europea, garantendoti la massima affidabilità e sicurezza."
  },
  "homeBox29": {
    "en": "A world of functions",
    "it": "Un mondo di funzioni"
  },
  "homeBox30": {
    "en": "Recharge your account simply and quickly via bank transfer or in cash at over 16,000 affiliated points of sale.",
    "it": "Ricarica il tuo conto in modo semplice e veloce tramite bonifico bancario o in contanti presso oltre 16.000 punti vendita convenzionati."
  },
  "homeBox31": {
    "en": "Access exclusive services such as PagoPA and purchase telephone recharges for all operators, as well as services dedicated to your interests: travel, games and much more.",
    "it": "Accedi a servizi esclusivi come PagoPA e acquista ricariche telefoniche per tutti gli operatori, oltre a servizi dedicati ai tuoi interessi: viaggi, giochi e molto altro."
  },
  "homeBox32": {
    "en": "",
    "it": ""
  },
  "homeBox33": {
    "en": "Support",
    "it": "Assistenza"
  },
  "homeBox34": {
    "en": "From opening an account to managing any need, we are here for you. Thanks to rich documentation and prompt, dedicated support, you can count on a premium service. Contact us easily via chat, phone or email: your satisfaction is our priority.",
    "it": "Dall'apertura del conto alla gestione di qualsiasi necessità, siamo qui per te. Grazie ad una ricca documentazione e ad un supporto rapido e dedicato, potrai contare su un servizio premium. Contattaci facilmente via chat, telefono o email: la tua soddisfazione è la nostra priorità."
  },
  "Accept": {
    "en": "Accept",
    "it": "Accetta"
  },
  "Decline": {
    "en": "Decline",
    "it": "Rifiuta"
  },
  "Cookie text": {
    "en": "Your cookie consent text in English",
    "it": "Your cookie consent text in Italian"
  },
  "HomeQue1_title": {
    "en": "Is CRH Money a Bank?",
    "it": "CRH Money è una Banca?"
},
"HomeQue1_text": {
    "en": "CRH Money is not a bank, as it doesn't use your money, but offers exclusive and technological banking services via web and smartphone. With a CRH Money account, you can have a European IBAN, execute and receive European (SEPA) and international (SWIFT) transfers, banking operations are guaranteed by Unlimit, a global company licensed as an Electronic Money Institution, leader in high-tech banking services.",
    "it": "CRH Money non è una banca, perché non utilizza il tuo denaro, ma offre servizi bancari esclusivi e tecnologici tramite web e smartphone. Con il conto CRH Money puoi avere un IBAN europeo, eseguire e ricevere bonifici europei (SEPA) e internazionali (SWIFT), le operazioni bancarie sono garantite da Unlimit, un'azienda globale con licenza di Istituto di moneta elettronica, leader del settore dei servizi bancari ad alta tecnologia"
},
"HomeQue2_title": {
    "en": "Can I use it from my computer?",
    "it": "Posso usarla dal computer?"
},
"HomeQue2_text": {
    "en": "CRH Money offers its customers access from Android and iOS APP and from computer with all browsers from my.crhmoney.com at no additional cost.",
    "it": "CRH Money offre ai propri clienti l'accesso da APP Android e IOS e dal computer con tutti i browser da my.crhmoney.com senza costi aggiuntivi."
},
"HomeQue3_title": {
    "en": "Can I withdraw from ATMs?",
    "it": "Posso Prelevare all'ATM?"
},
"HomeQue3_text": {
    "en": "With CRH Money you can have up to two physical cards to withdraw from all banking institutions and you can instantly get up to 14 virtual cards at no cost that allow you all physical card operations including cash withdrawal** on contactless ATMs.",
    "it": "Con CRH Money puoi avere fino a due carte fisiche per poter prelevare in tutti gli istituti bancari e puoi ottenere istantaneamente e senza costi fino a 14 carte virtuali che ti permettono tutte le operazioni di una carta fisica compreso il prelievo di contanti** sugli ATM dotati di contactless."
},
"HomeQue4_title": {
    "en": "Can I add it to Google Pay or Apple Pay?",
    "it": "Posso aggiungerlo a Google Pay o Apple Pay?"
},
"HomeQue4_text": {
    "en": "All our cards are on the Mastercard Debit network and can be used with Apple and Google wallets.",
    "it": "Tutte le nostre carte sono sul circuito Mastercard Debit e possono essere usate con i wallet di Apple e Google."
},
"HomeQue5_title": {
    "en": "What other features does CRH Money offer?",
    "it": "Che altre funzionalità offre CRH Money?"
},
"HomeQue5_text": {
    "en": "CRH Money offers numerous other payment features such as purchasing vouchers from the most prestigious brands, mobile phone top-ups for all operators, payment of pre-marked bills, PagoPA, MAV and RAV. You can have a physical card and 7 virtual cards for each plan.",
    "it": "CRH Money offre numerose altre funzionalità di pagamento come l'acquisto dei vouchers dei marchi più prestigiosi, le ricariche telefoniche di tutti gli operatori, pagamento di bollettini premarcati, Pago PA, MAV e RAV. Puoi avere la carta fisica e 7 carte virtuali per ogni piano."
},
"HomeQue6_title": {
    "en": "What support does CRH Money offer?",
    "it": "Che assistenza offre CRH Money?"
},
"HomeQue6_text": {
    "en": "CRH Money offers a complete portal of questions and answers and a chat with real-time operators to give you quick assistance and an unmatched user experience.",
    "it": "CRH Money offre un portale completo di domande e risposte e una chat con operatori in tempo reale per darti un'assistenza rapida e un'esperienza d'uso impareggiabile."
},
"AZIENDA": {
    "en": "COMPANY",
    "it": "AZIENDA"
  },
  "LEGALE": {
    "en": "LEGAL",
    "it": "LEGALE"
  },
  "MARKETING": {
    "en": "MARKETING",
    "it": "MARKETING"
  },
  "Chi siamo": {
    "en": "About Us",
    "it": "Chi siamo"
  },
  "Valori": {
    "en": "Values",
    "it": "Valori"
  },
  "Privacy IT": {
    "en": "Privacy IT",
    "it": "Privacy IT"
  },
  "Privacy EN": {
    "en": "Privacy EN",
    "it": "Privacy EN"
  },
"HomeNav1": {
    "en": "CRH Money",
    "it": "CRH Money"
},
"HomeNav2": {
    "en": "Open Account",
    "it": "APRI IL CONTO"
},
"HomeNav3": {
    "en": "Client Access",
    "it": "ACCESSO CLIENTI"
},
"HomeNav4": {
    "en": "CRH Money",
    "it": "CRH Money"
},
"HomeNav5": {
    "en": "Personal",
    "it": "Personale"
},
"HomeNav6": {
    "en": "Business",
    "it": "Business"
},
"homeBox91": {
    "en": "Download the CRH Money App",
    "it": "Scarica l'App CRH Money"
},
"homeBox92": {
    "en": "Open your account in 5 minutes",
    "it": "Apri il tuo conto in 5 minuti"
},
"homeBox93": {
    "en": "and get your virtual card",
    "it": "e ottieni la tua carta virtuale"
},
"homeBox94": {
    "en": "to operate immediately!",
    "it": "per operare da subito!"
},
"FooterBox1": {
    "en": "COMPANY",
    "it": "AZIENDA"
},
"FooterBox2": {
    "en": "About Us",
    "it": "Chi siamo"
},
"FooterBox3": {
    "en": "Values",
    "it": "Valori"
},
"FooterBox4": {
    "en": "LEGAL",
    "it": "LEGALE"
},
"FooterBox5": {
    "en": "Privacy",
    "it": "Privacy"
},
"FooterBox6": {
    "en": "Privacy IT",
    "it": "Privacy EN"
},
"FooterBox7": {
    "en": "Terms and Conditions IT",
    "it": "Termini e condizioni IT"
},
"FooterBox8": {
    "en": "Terms and Conditions EN",
    "it": "Termini e condizioni EN"
},
"FooterBox9": {
    "en": "Cookie IT",
    "it": "Cookie IT"
},
"FooterBox10": {
    "en": "Cookie EN",
    "it": "Cookie EN"
},
"FooterBox11": { 
   "en": "Cards", 
   "it": "Carte" 
},
"FooterBox12": { 
   "en": "Wallets", 
   "it": "Wallet" 
},
"FooterBox16": {
    "en": "MARKETING",
    "it": "MARKETING"
},
"FooterBox17": {
    "en": "News",
    "it": "News"
},
"FooterBox17b": {
    "en": "For the Press",
    "it": "Per la Stampa"
},
"FooterBox18": {
    "en": "CRH MONEY",
    "it": "CRH MONEY"
},
"FooterBox19": {
    "en": "Plans",
    "it": "Piani"
},
"FooterBox20": {
    "en": "Support",
    "it": "Assistenza"
},
"FooterBox21": {
    "en": "User Guide IT",
    "it": "Guida Utente IT"
},
"FooterBox22": {
    "en": "User Guide EN",
    "it": "Guida Utente EN"
},
"FooterBox23": {
    "en": "The CRH Money account and Mastercard are issued by Unlimint, authorized by the Bank of Cyprus with an electronic money institution license to issue e-money and is a member of the Mastercard Scheme. Please note that electronic money products are not covered by the Republic of Cyprus Deposit Insurance Scheme. We ensure that any funds received from you are held in a segregated account so that, in the event that Unlimint becomes insolvent, your funds will be protected against claims made by our creditors.",
    "it": "Il conto CRH Money e Mastercard sono emessi da Unlimint, autorizzato dalla Banca di Cipro con la licenza di istituzione di moneta elettronica per emettere e-money ed è membro dello Schema Mastercard. Si prega di notare che i prodotti di moneta elettronica non sono coperti dal Sistema di Assicurazione dei Depositi della Repubblica di Cipro. Assicuriamo che qualsiasi fondo ricevuto da voi sia tenuto in un conto segregato in modo che, nel caso in cui Unlimint diventi insolvente, i vostri fondi saranno protetti contro le richieste fatte dai nostri creditori."
},
"FooterBox24": {
    "en": "Accounts",
    "it": "Conti"
},
"FooterBox25": {
    "en": "Costs",
    "it": "Costi"
},
"FooterBox26": {
    "en": "Sales",
    "it": "Vendite"
},
"Personal1": {
    "en": "Exclusivity is Personal",
    "it": "L'esclusività è Personale"
  },
  "Personal2": {
    "en": "Entering the CRH Money world means accessing an exclusive service, designed to offer you security, efficiency and tailored benefits. Thanks to a perfect balance between tradition and innovation, we guarantee you a premium experience, supported by dedicated assistance and cutting-edge tools.",
    "it": "Entrare nel mondo CRH Money significa accedere a un servizio esclusivo, pensato per offrirti sicurezza, efficienza e vantaggi su misura. Grazie a un perfetto equilibrio tra tradizione e innovazione, ti garantiamo un'esperienza premium, supportata da un'assistenza dedicata e strumenti all'avanguardia."
  },
  "Personal3": {
    "en": "Start building your financial future with CRH Money today",
    "it": "Inizia oggi a costruire il tuo futuro finanziario con CRH Money"
  },
  "Business1": {
    "en": "Exclusivity is Business",
    "it": "L'esclusività è Business"
  },
  "Business2": {
    "en": "Relying on CRH Money Business means accessing advanced financial solutions, designed to meet the needs of companies and professionals with VAT numbers. We offer tailored tools to simplify financial management, ensuring security, efficiency and dedicated support.",
    "it": "Affidarsi a CRH Money Business significa accedere a soluzioni finanziarie avanzate, progettate per soddisfare le esigenze di aziende e professionisti con Partita IVA. Offriamo strumenti su misura per semplificare la gestione finanziaria, garantendo sicurezza, efficienza e un supporto dedicato."
  },
  "Business3": {
    "en": "Choose CRH Money to grow your Business with a reliable and exclusive partner.",
    "it": "Scegli CRH Money per far crescere il tuo Business con un partner affidabile ed esclusivo."
  },
  "Business4": {
  "en": "Quick account opening",
  "it": "Apertura rapida del conto"
},
"Business5": {
  "en": "Multi IBAN",
  "it": "Multi IBAN"
},
"Business6": {
  "en": "Visa Corporate debit card",
  "it": "Carta di debito Visa Corporate"
},
"Business7": {
  "en": "Corporate virtual cards",
  "it": "Carte virtuali aziendali"
},
"Business8": {
  "en": "An exclusive experience",
  "it": "Un'esperienza esclusiva"
},
"Business9": {
  "en": "of payment",
  "it": "di pagamento"
},

}

export default translations;