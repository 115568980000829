import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Home from './pages/Home';
import Personal from './pages/Personal';
import Business from './pages/Business';
import Prodotti from './pages/Prodotti';
import News from './pages/News';
import Footer from './components/Footer';
import Privacy from './pages/Privacy';
import { LanguageProvider, useLanguage  } from './LanguageContext';
import Cards from './pages/cards';
import Landing from './pages/landing';
import ContactUs from './pages/Contact_us';
import Terms from './pages/Terms';
import Assistance from './pages/Assistance';
import Intercom from '@intercom/messenger-js-sdk';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#666666',
      paper: '#666666',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
    },
  },
});

function App() {
  const { language } = useLanguage ();

  useEffect(() => {
    Intercom({
      app_id: 'g3g0349a',
    });
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{
        maxWidth: '1920px',
        margin: '0 auto',
        position: 'relative',
        overflow: 'hidden', // This will hide anything that goes beyond 1920px
      }}>
        <Router>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            bgcolor: 'background.default',
          }}>


            <Box component="main" sx={{
              flexGrow: 1,
              width: '100%',
              padding: '0 16px', // Add some padding on the sides
            }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/personal" element={<Personal/>} />
                <Route path="prodotti" element={<Prodotti />} />
                <Route path="news" element={<News />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/business" element={<Business />} />
                <Route path="/landing" element={<Landing />} />
                <Route path="/contact_us" element={<ContactUs />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/assistance" element={<Assistance />} />
                <Route path="/rebranding" element={<Landing />} />
              </Routes>
            </Box>

            <Footer />

          </Box>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default function AppWrapper() {
  return (
    <LanguageProvider>
      <App />
    </LanguageProvider>
  );
}