import React, { createContext, useContext, useState, useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';

const LanguageContext = createContext();

// Rename to follow React Hook naming convention
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('it'); // Default language is Italian
  
  const switchLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };
  
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });
  
  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const { latitude, longitude } = coords;
      // Default to Italian if geolocation is available
      setLanguage('it');
    } else {
      // Fallback to browser language detection
      const browserLanguage = navigator.language || navigator.useLanguage;
      if (browserLanguage.startsWith('en')) {
        setLanguage('en');
      } else {
        setLanguage('it');
      }
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]);
  
  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;