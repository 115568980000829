// translationUtils.js
import { defaultLanguage } from '../LanguageContext'; // Add this import

// This function doesn't use hooks
export function t(key, translations, language = 'it') {
  // Use the language parameter instead of calling useLanguage() hook
  
  // Check if the translations object has the key and language defined
  if (translations[key] && translations[key][language]) {
    return translations[key][language];
  } else {
    // Return the key itself if translation is not found
    return key;
  }
}