import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Typography, useMediaQuery, Accordion, AccordionSummary, useTheme, AccordionDetails, Container, IconButton, Drawer, MenuItem, Divider } from '@mui/material';
import { useLanguage } from '../LanguageContext';
import { t } from '../utils/translationUtils';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import translations from '../translations';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Homepage = () => {

  const navigate = useNavigate();
  const { language, switchLanguage } = useLanguage()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleLanguage = () => {
    switchLanguage(language === 'en' ? 'it' : 'en');
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleImageClick = (imageName) => {
    const isAndroid = /android/i.test(navigator.userAgent);
    const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

    if (isMobile) {
      if (imageName === 'Home94' && isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.cartareale.app&hl=en_NZ&gl=US&pli=1';
      } else if (imageName === 'Home93' && isIOS) {
        window.location.href = 'https://apps.apple.com/us/app/carta-reale/id6450711819';
      }
    }
  };

  const handleApriIlConto = () => {
    if (isMobile) {
      const isAndroid = /android/i.test(navigator.userAgent);
      const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

      if (isAndroid) {
        window.location.href = 'carta://';
        setTimeout(() => {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.cartareale.app&hl=en_NZ&gl=US&pli=1';
        }, 500);
      } else if (isIOS) {
        window.location.href = 'carta://';
        setTimeout(() => {
          window.location.href = 'https://apps.apple.com/us/app/carta-reale/id6450711819';
        }, 500);
      }
    } else {
      window.open('https://my.crhmoney.com/register', '_blank');
    }
  };


  return (
    <>
      <Box sx={{
        backgroundColor: '#ffffff',
        color: '#000000',
        minHeight: '100vh',
        width: '100%',
        position: 'relative'
      }}>

        <Box
          sx={{
            width: '100%',
            backgroundColor: '#ffffff',
            padding: '20px',
            // Change to block layout specifically for the problematic range
            display: { xs: 'block', md: 'block', lg: 'block', xl: 'flex' }
          }}
        >
          {/* Left half - Logo */}
          <Box sx={{
            width: { xs: '100%', xl: '50%' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img
              src="/images/logog.svg"
              alt={t("HomeNav1", translations, language)}
              style={{
                height: '200px',
                maxWidth: '100%',
                objectFit: 'contain'
              }}
            />
          </Box>

          {/* Right half - Navigation and buttons */}
          <Box sx={{
            width: { xs: '100%', xl: '50%' },
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'column',
            gap: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            mt: { md: 3, xl: 0 }  // Add margin-top when in stacked layout
          }}>
            {/* Top row - Account, Access, Language */}
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '30px'
            }}>
              {/* Open Account */}
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleApriIlConto}>
                <img src="/images/Navbar2.webp" alt="Open Account" style={{ width: '70px', marginRight: '12px' }} />
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' },
                    color: '#000000'
                  }}
                >
                  {t("HomeNav2", translations, language)}
                </Typography>
              </Box>

              {/* Client Access */}
              <Box
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => window.open('https://my.crhmoney.com', '_blank')}
              >
                <img src="/images/Navbar3.webp" alt="Client Access" style={{ width: '70px', marginRight: '12px' }} />
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' },
                    color: '#000000'
                  }}
                >
                  {t("HomeNav3", translations, language)}
                </Typography>
              </Box>

              {/* Language Selector */}
              <Box
                onClick={() => switchLanguage(language === 'en' ? 'it' : 'en')}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {language === 'en' ? (
                  <img src="/images/Usa.svg" alt="English" style={{ width: '36px' }} />
                ) : (
                  <img src="/images/Ita.svg" alt="Italian" style={{ width: '36px' }} />
                )}
              </Box>
            </Box>

            {/* Bottom row - Navigation buttons */}
            <Box sx={{
              display: 'flex',
              gap: '40px', 
              alignItems: 'center'
            }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#000000',
                  color: '#ffffff',
                  borderRadius: '30px', // More rounded
                  '&:hover': { backgroundColor: '#000000' },
                  textTransform: 'none',
                  padding: '10px 50px', // Wider padding
                  fontFamily: 'Poppins',
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' },
                }}
              >
                {t("HomeNav4", translations, language)}
              </Button>

              <Button
                onClick={() => navigate('/personal')}
                sx={{
                  color: '#000000',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' },
                  padding: '10px 50px',
                  fontFamily: 'Poppins',
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' }
                }}
              >
                {t("HomeNav5", translations, language)}
              </Button>

              <Button
                onClick={() => navigate('/business')}
                sx={{
                  color: '#000000',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' },
                  padding: '10px 50px',
                  fontFamily: 'Poppins',
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' }
                }}
              >
                {t("HomeNav6", translations, language)}
              </Button>
            </Box>
          </Box>
          {/* Mobile Menu Button - Only visible on mobile */}
          <Box sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'flex-end',
            position: 'absolute',
            top: '20px',
            right: '20px',
            zIndex: 1300  // Higher z-index to ensure it's above everything
          }}>
            <IconButton
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              sx={{
                color: mobileMenuOpen ? '#FFFFFF' : '#000000',
                padding: '12px'
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Box>

          {/* Mobile Menu Drawer */}
          <Drawer
            anchor="right"
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                width: '100%',
                backgroundColor: '#000000',
                color: '#ffffff',
              }
            }}
          >
            <Box sx={{ p: 2 }}>
              {/* Header with back button */}
              <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => setMobileMenuOpen(false)}
                  sx={{ ml: 1, color: '#FFFFFF' }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 2, color: '#FFFFFF' }}>
                  Menu
                </Typography>
              </Box>
              <Divider sx={{ backgroundColor: '#FFFFFF' }} />

              {/* Menu Items */}
              <Box sx={{ py: 2 }}>
                {/* Home */}
                <MenuItem
                  onClick={() => {
                    navigate('/');
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav4", translations, language)}
                </MenuItem>

                {/* Personal */}
                <MenuItem
                  onClick={() => {
                    navigate('/personal');
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav5", translations, language)}
                </MenuItem>

                {/* Business */}
                <MenuItem
                  onClick={() => {
                    navigate('/business');
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav6", translations, language)}
                </MenuItem>

                <Divider sx={{ backgroundColor: '#FFFFFF', my: 1 }} />

                {/* Open Account */}
                <MenuItem
                  onClick={() => {
                    handleApriIlConto();
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav2", translations, language)}
                </MenuItem>

                {/* Client Access */}
                <MenuItem
                  onClick={() => {
                    window.open('https://my.crhmoney.com', '_blank');
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav3", translations, language)}
                </MenuItem>

                <Divider sx={{ backgroundColor: '#FFFFFF', my: 1 }} />

                {/* Language Toggle */}
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                  <IconButton
                    onClick={() => {
                      switchLanguage(language === 'en' ? 'it' : 'en');
                      setMobileMenuOpen(false);
                    }}
                    sx={{ p: 1, color: '#FFFFFF' }}
                  >
                    {language === 'en' ? (
                      <img src="/images/Usa.svg" alt="English" style={{ width: '36px' }} />
                    ) : (
                      <img src="/images/Ita.svg" alt="Italian" style={{ width: '36px' }} />
                    )}
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>


        <Box>
          <Grid container>
            {/* Left side with content */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                pr: { xs: '1rem', sm: '2rem', md: '2rem', lg: '2rem' },
                pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
                pt: { xs: '2rem', sm: '3rem', md: '3rem', lg: '0rem' },
                pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
              }}
            >
              <Box className="HomeBox1" sx={{ maxWidth: '530px' }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: { xs: '36px', sm: '42px', md: '48px', lg: '48px' },
                    color: '#000000',
                    mb: { xs: 2, sm: 3, md: 3, lg: 3 },
                    lineHeight: 1.2,
                  }}
                >
                  {t("homeBox1_title", translations, language)}
                </Typography>

                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '24px', sm: '30px', md: '36px', lg: '36px' },
                    color: '#000000',
                    mb: { xs: 3, sm: 4, md: 4, lg: 4 },
                  }}
                >
                  {t("homeBox1_subtitle", translations, language)}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: { xs: '16px', sm: '20px', md: '24px', lg: '24px' },
                    color: '#000000',
                    mb: { xs: 2, sm: 2, md: 2, lg: 2 },
                  }}
                >
                  {t("homeBox1_text1", translations, language)}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    fontSize: { xs: '16px', sm: '20px', md: '24px', lg: '24px' },
                    color: '#000000',
                    mb: { xs: 4, sm: 5, md: 5, lg: 5 },
                  }}
                >
                  {t("homeBox1_text2", translations, language)}
                </Typography>

                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: { xs: '16px', sm: '20px', md: '24px', lg: '24px' },
                    color: '#000000',
                    mb: { xs: 4, sm: 5, md: 5, lg: 5 },
                    width: '100%',
                  }}
                >
                  {t("homeBox1_cta", translations, language)}
                </Typography>

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <Button
                    component="a"
                    onClick={handleApriIlConto}
                    variant="contained"
                    sx={{
                      backgroundColor: '#BB2A26', // Rosso Savoia from color palette
                      color: '#ffffff',
                      borderRadius: '50px',
                      fontFamily: 'Poppins',
                      fontWeight: 400,
                      fontSize: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                      padding: '10px 30px',
                      textTransform: 'uppercase',
                      '&:hover': { backgroundColor: '#9e2420' },

                    }}
                  >
                    {t("homeBox1_button", translations, language)}
                  </Button>
                </Box>
              </Box>
            </Grid>

            {/* Right side with image */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: { xs: '2rem', sm: '2rem', md: '4rem', lg: '5rem' },
                pb: { xs: '2rem', sm: '2rem', md: '4rem', lg: '5rem' },
              }}
            >
              <Box
                sx={{
                  width: { xs: '80%', sm: '70%', md: '80%', lg: '80%' },
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src="/images/cubo.webp"
                  alt="CRH Money Cube"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    borderRadius: '8px',

                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            py: 8,
            px: { xs: 4, md: 8 },
            backgroundColor: '#000000',
            color: '#ffffff',
            width: { xs: '100%', sm: '92%', md: '85%', lg: '80%' },
            margin: '0 auto',
            borderRadius: '20px',
          }}
        >
          <Box
            sx={{
              border: '2px solid transparent',
              borderRadius: '8px',
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '800px',
              margin: '0 auto',
              textAlign: 'center',
            }}
          >
            <Typography variant="h1" sx={{ fontWeight: 'bold', fontSize: { xs: '2rem', sm: '3rem', md: '3rem', lg: '3rem' }, mb: 2 }}>
              {t("homeBox21", translations, language)}
              <br />
              {t("homeBox22", translations, language)}
            </Typography>
            <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.25rem', lg: '2.25rem' } }}>
              {t("homeBox23", translations, language)}
              <br />
              {t("homeBox24", translations, language)}
            </Typography>
          </Box>
          <Grid container spacing={4} sx={{ mt: 8 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, mx: { xs: '10px', sm: '20px', md: '30px', lg: '140px' } }}>
                <img
                  src="/images/Home21.svg"
                  alt="Liberty Icon"
                  style={{ width: '100px', height: 'auto', marginBottom: '1rem' }}
                />
                <Box>
                  <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.25rem', lg: '2.25rem' } }}>
                    {t("homeBox25", translations, language)}
                  </Typography>
                  <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                    {t("homeBox26", translations, language)}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, mx: { xs: '10px', sm: '20px', md: '30px', lg: '140px' } }}>
                <img
                  src="/images/Home24.svg"
                  alt="Security Icon"
                  style={{ width: '100px', height: 'auto', marginBottom: '1rem' }}
                />
                <Box>
                  <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.25rem', lg: '2.25rem' } }}>
                    {t("homeBox27", translations, language)}
                  </Typography>
                  <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                    {t("homeBox28", translations, language)}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, mx: { xs: '10px', sm: '20px', md: '30px', lg: '140px' } }}>
                <img
                  src="/images/Home22.svg"
                  alt="Features Icon"
                  style={{ width: '100px', height: 'auto', marginBottom: '1rem' }}
                />
                <Box>
                  <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.25rem', lg: '2.25rem' } }}>
                    {t("homeBox29", translations, language)}
                  </Typography>
                  <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                    {t("homeBox30", translations, language)}<br></br>
                    {t("homeBox31", translations, language)}
                  </Typography>
                  <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                    {t("homeBox32", translations, language)}
                    <br></br>
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 2, mx: { xs: '10px', sm: '20px', md: '30px', lg: '140px' } }}>
                <img
                  src="/images/Home23.svg"
                  alt="Support Icon"
                  style={{ width: '100px', height: 'auto', marginBottom: '1rem' }}
                />
                <Box>
                  <Typography variant="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2.25rem', md: '2.25rem', lg: '2.25rem' } }}>
                    {t("homeBox33", translations, language)}
                  </Typography>
                  <Typography variant="h3" sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '1.25rem' } }}>
                    {t("homeBox34", translations, language)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{
          position: 'relative',
          width: '100%',
          minHeight: '100vh',
          backgroundColor: '#C4C0C3', // Gray background
          padding: { xs: '2rem', sm: '3rem', md: '4rem', lg: '5rem' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {/* White container with rounded corners - matching the size of black box above */}
          <Box sx={{
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            padding: { xs: '2rem', sm: '3rem', md: '4rem' },
            width: { xs: '100%', sm: '92%', md: '85%', lg: '87%' }, // Match the width of black box
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '0 auto', // Center the box
          }}>
            {/* Title - Poppins Bold 700, size 48 */}
            <Typography variant="h1" sx={{
              fontFamily: 'Poppins',
              fontWeight: 700,
              fontSize: { xs: '36px', sm: '42px', md: '48px', lg: '48px' },
              color: '#000000',
              textAlign: 'center',
              mb: { xs: 3, sm: 4, md: 5 },
            }}>
              {t("homeBox91", translations, language)}
            </Typography>

            {/* Subtitle - Poppins Bold 700, size 36 */}
            <Typography variant="h2" sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: { xs: '24px', sm: '30px', md: '36px', lg: '36px' },
              color: '#000000',
              textAlign: 'center',
              mb: { xs: 4, sm: 5, md: 6 },
            }}>
              {t("homeBox92", translations, language)}<br />
              {t("homeBox93", translations, language)}<br />
              {t("homeBox94", translations, language)}
            </Typography>

            {/* Main text - Poppins Regular 400, size 24 */}
            <Typography variant="body1" sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: { xs: '18px', sm: '20px', md: '24px', lg: '24px' },
              color: '#000000',
              textAlign: 'center',
              mb: { xs: 4, sm: 5, md: 6 },
            }}>

            </Typography>



            {/* Phone images */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              mb: { xs: 4, sm: 5, md: 6 },
            }}>
              <img
                src="/images/Home91.webp"
                alt="CRH Money App Screenshots"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  maxHeight: '600px',
                }}
              />
            </Box>

            {/* QR Codes and store buttons */}
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              gap: { xs: 3, sm: 4, md: 6 },
            }}>
              {/* Google Play */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <img
                  src="/images/Home94.svg"
                  alt="Google Play"
                  style={{ height: '200px', cursor: 'pointer' }}
                  onClick={() => handleImageClick('Home94')}
                />
              </Box>

              {/* App Store */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <img
                  src="/images/Home93.svg"
                  alt="App Store"
                  style={{ height: '200px', cursor: 'pointer' }}
                  onClick={() => handleImageClick('Home93')}
                />
              </Box>
            </Box>
          </Box>

          {/* Web access section - also matching width */}
          <Box sx={{
            width: { xs: '100%', sm: '92%', md: '85%', lg: '80%' }, // Match the width of boxes above
            margin: '0 auto',
            mt: { xs: 4, sm: 6, md: 8 },
            textAlign: 'center',
          }}>

          </Box>
        </Box>


        <Box sx={{
          minHeight: '62vh',
          alignItems: 'center',
          display: 'flex',
        }}>

          <Box sx={{

            backgroundColor: '#C4C0C3',
            borderRadius: '20px',
            position: 'relative',
            overflow: 'hidden',
            width: { xs: '100%', sm: '92%', md: '85%', lg: '79%' },
            margin: '0 auto',
            borderRadius: '20px',
            height: '90%',
            minHeight: '50vh',
            display: 'flex', // Add flex display
            alignItems: 'center', // Center content vertically
          }}>
            {/* Background Image */}
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: '100%',
                width: '40%',
                backgroundImage: `url('/images/Homeback.svg')`,
                backgroundSize: 'contain',
                backgroundPosition: 'left center',
                backgroundRepeat: 'no-repeat',
                opacity: 0.2
              }}
            />

            <Container sx={{
              position: 'relative',
              mt: '3rem',
              zIndex: 1,
              py: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              '& > *': {
                width: { xs: '90%', sm: '80%', md: '70%', lg: '60%' },
                maxWidth: { xs: '90%', sm: '80%', md: '70%', lg: '60%' }
              }
            }} >
              {/* Question 1 */}
              <Accordion
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mb: 2,
                  borderRadius: '30px !important',
                  '&:before': {
                    display: 'none',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderRadius: '30px',
                    padding: '0 20px' // Added padding
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<AddIcon sx={{ color: 'white' }} />}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}> {/* Added left padding */}
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.4rem' },
                        flexGrow: 1
                      }}
                    >
                      {t("HomeQue1_title", translations, language)}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '30px',
                  borderBottomRightRadius: '30px',
                  py: 3,
                  px: 4 // Added padding
                }}>
                  <Typography sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem', lg: '1.2rem' },
                    color: 'black'
                  }}>
                    {t("HomeQue1_text", translations, language)}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Question 2 */}
              <Accordion
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mb: 2,
                  borderRadius: '30px !important',
                  '&:before': {
                    display: 'none',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderRadius: '30px',
                    padding: '0 20px'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<AddIcon sx={{ color: 'white' }} />}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.4rem' },
                        flexGrow: 1
                      }}
                    >
                      {t("HomeQue2_title", translations, language)}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '30px',
                  borderBottomRightRadius: '30px',
                  py: 3,
                  px: 4
                }}>
                  <Typography sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem', lg: '1.2rem' },
                    color: 'black'
                  }}>
                    {t("HomeQue2_text", translations, language)}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Question 3 */}
              <Accordion
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mb: 2,
                  borderRadius: '30px !important',
                  '&:before': {
                    display: 'none',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderRadius: '30px',
                    padding: '0 20px'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<AddIcon sx={{ color: 'white' }} />}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.4rem' },
                        flexGrow: 1
                      }}
                    >
                      {t("HomeQue3_title", translations, language)}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '30px',
                  borderBottomRightRadius: '30px',
                  py: 3,
                  px: 4
                }}>
                  <Typography sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem', lg: '1.2rem' },
                    color: 'black'
                  }}>
                    {t("HomeQue3_text", translations, language)}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Question 4 */}
              <Accordion
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mb: 2,
                  borderRadius: '30px !important',
                  '&:before': {
                    display: 'none',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderRadius: '30px',
                    padding: '0 20px'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<AddIcon sx={{ color: 'white' }} />}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.4rem' },
                        flexGrow: 1
                      }}
                    >
                      {t("HomeQue4_title", translations, language)}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '30px',
                  borderBottomRightRadius: '30px',
                  py: 3,
                  px: 4
                }}>
                  <Typography sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem', lg: '1.2rem' },
                    color: 'black'
                  }}>
                    {t("HomeQue4_text", translations, language)}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Question 5 */}
              <Accordion
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mb: 2,
                  borderRadius: '30px !important',
                  '&:before': {
                    display: 'none',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderRadius: '30px',
                    padding: '0 20px'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<AddIcon sx={{ color: 'white' }} />}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.4rem' },
                        flexGrow: 1
                      }}
                    >
                      {t("HomeQue5_title", translations, language)}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '30px',
                  borderBottomRightRadius: '30px',
                  py: 3,
                  px: 4
                }}>
                  <Typography sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem', lg: '1.2rem' },
                    color: 'black'
                  }}>
                    {t("HomeQue5_text", translations, language)}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {/* Question 6 */}
              <Accordion
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mb: 2,
                  borderRadius: '30px !important',
                  '&:before': {
                    display: 'none',
                  },
                  '& .MuiAccordionSummary-root': {
                    borderRadius: '30px',
                    padding: '0 20px'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<AddIcon sx={{ color: 'white' }} />}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.4rem' },
                        flexGrow: 1
                      }}
                    >
                      {t("HomeQue6_title", translations, language)}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{
                  backgroundColor: 'white',
                  borderBottomLeftRadius: '30px',
                  borderBottomRightRadius: '30px',
                  py: 3,
                  px: 4
                }}>
                  <Typography sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem', lg: '1.2rem' },
                    color: 'black'
                  }}>
                    {t("HomeQue6_text", translations, language)}
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Homepage;