import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Typography, useMediaQuery, Accordion, AccordionSummary, useTheme, AccordionDetails, Container, IconButton, Drawer, MenuItem, Divider } from '@mui/material';
import { useLanguage } from '../LanguageContext';
import { t } from '../utils/translationUtils';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import translations from '../translations';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Personal = () => {

  const navigate = useNavigate();
  const { language, switchLanguage } = useLanguage()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleLanguage = () => {
    switchLanguage(language === 'en' ? 'it' : 'en');
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const handleImageClick = (imageName) => {
    const isAndroid = /android/i.test(navigator.userAgent);
    const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

    if (isMobile) {
      if (imageName === 'Home94' && isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.cartareale.app&hl=en_NZ&gl=US&pli=1';
      } else if (imageName === 'Home93' && isIOS) {
        window.location.href = 'https://apps.apple.com/us/app/carta-reale/id6450711819';
      }
    }
  };

  const handleApriIlConto = () => {
    if (isMobile) {
      const isAndroid = /android/i.test(navigator.userAgent);
      const isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

      if (isAndroid) {
        window.location.href = 'carta://';
        setTimeout(() => {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.cartareale.app&hl=en_NZ&gl=US&pli=1';
        }, 500);
      } else if (isIOS) {
        window.location.href = 'carta://';
        setTimeout(() => {
          window.location.href = 'https://apps.apple.com/us/app/carta-reale/id6450711819';
        }, 500);
      }
    } else {
      window.open('https://my.crhmoney.com/register', '_blank');
    }
  };

  return (
    <>
      {/* Main container - add !important to override any parent styles */}
      <Box sx={{
        background: 'linear-gradient(135deg, #000000 0%, #354BA7 50%, #000000 100%) !important',
        color: '#ffffff',
        minHeight: '100vh',
        width: '100%',
        position: 'relative'
      }}>
        {/* Top navigation container */}
        <Box
          sx={{
            width: '100%',
            padding: '20px',
            backgroundColor: 'transparent !important', /* Force transparency */
            display: { xs: 'block', md: 'block', lg: 'block', xl: 'flex' }
          }}
        >
          {/* Logo container */}
          <Box sx={{
            width: { xs: '100%', xl: '50%' },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent !important' /* Force transparency */
          }}>
 
            <img
              src="/images/logog.svg"
              alt={t("HomeNav1", translations, language)}
              style={{
                height: '200px',
                maxWidth: '100%',
                objectFit: 'contain'
              }}
            />
          </Box>

          {/* Right half - Navigation and buttons */}
          <Box sx={{
            width: { xs: '100%', xl: '50%' },
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'column',
            gap: '30px',
            justifyContent: 'center',
            alignItems: 'center',
            mt: { md: 3, xl: 0 }  // Add margin-top when in stacked layout
          }}>
            {/* Top row - Account, Access, Language */}
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '30px'
            }}>
              {/* Open Account */}
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleApriIlConto}>
                <img src="/images/Navbar2.webp" alt="Open Account" style={{ width: '70px', marginRight: '12px' }} />
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' },
                    color: '#FFFFFF'
                  }}
                >
                  {t("HomeNav2", translations, language)}
                </Typography>
              </Box>

              {/* Client Access */}
              <Box
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => window.open('https://my.crhmoney.com', '_blank')}
              >
                <img src="/images/Navbar3.webp" alt="Client Access" style={{ width: '70px', marginRight: '12px' }} />
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' },
                    color: '#FFFFFF'
                  }}
                >
                  {t("HomeNav3", translations, language)}
                </Typography>
              </Box>

              {/* Language Selector */}
              <Box
                onClick={() => switchLanguage(language === 'en' ? 'it' : 'en')}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {language === 'en' ? (
                  <img src="/images/Usa.svg" alt="English" style={{ width: '36px' }} />
                ) : (
                  <img src="/images/Ita.svg" alt="Italian" style={{ width: '36px' }} />
                )}
              </Box>
            </Box>

            {/* Bottom row - Navigation buttons */}
            <Box sx={{
              display: 'flex',
              gap: '40px', // Increased gap between buttons
              alignItems: 'center'
            }}>
              <Button
                onClick={() => navigate('/')}
                sx={{
                  color: '#FFFFFF',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' },
                  padding: '10px 50px',
                  fontFamily: 'Poppins',
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' }
                }}
              >
                {t("HomeNav4", translations, language)}
              </Button>

              <Button
                sx={{
                  backgroundColor: '#ffffff',
                  color: '#000000',
                  borderRadius: '30px',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: '#ffffff' },
                  padding: '10px 50px',
                  fontFamily: 'Poppins',
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' }
                }}
              >
                {t("HomeNav5", translations, language)}
              </Button>

              <Button
                onClick={() => navigate('/Business')}
                sx={{
                  color: '#FFFFFF',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'transparent', textDecoration: 'underline' },
                  padding: '10px 50px',
                  fontFamily: 'Poppins',
                  fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '24px' }
                }}
              >
                {t("HomeNav6", translations, language)}
              </Button>
            </Box>
          </Box>
          {/* Mobile Menu Button - Only visible on mobile */}
          <Box sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'flex-end',
            position: 'absolute',
            top: '20px',
            right: '20px',
            zIndex: 1300  // Higher z-index to ensure it's above everything
          }}>
            <IconButton
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              sx={{
                color:'#FFFFFF',
                padding: '12px'
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Box>

          {/* Mobile Menu Drawer */}
          <Drawer
            anchor="right"
            open={mobileMenuOpen}
            onClose={() => setMobileMenuOpen(false)}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                width: '100%',
                backgroundColor: '#000000',
                color: '#ffffff',
              }
            }}
          >
            <Box sx={{ p: 2 }}>
              {/* Header with back button */}
              <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => setMobileMenuOpen(false)}
                  sx={{ ml: 1, color: '#FFFFFF' }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 2, color: '#FFFFFF' }}>
                  Menu
                </Typography>
              </Box>
              <Divider sx={{ backgroundColor: '#FFFFFF' }} />

              {/* Menu Items */}
              <Box sx={{ py: 2 }}>
                {/* Home */}
                <MenuItem
                  onClick={() => {
                    navigate('/');
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav4", translations, language)}
                </MenuItem>

                {/* Personal */}
                <MenuItem
                  onClick={() => {
                    navigate('/personal');
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav5", translations, language)}
                </MenuItem>

                {/* Business */}
                <MenuItem
                  onClick={() => {
                    navigate('/business');
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav6", translations, language)}
                </MenuItem>

                <Divider sx={{ backgroundColor: '#FFFFFF', my: 1 }} />

                {/* Open Account */}
                <MenuItem
                  onClick={() => {
                    handleApriIlConto();
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav2", translations, language)}
                </MenuItem>

                {/* Client Access */}
                <MenuItem
                  onClick={() => {
                    window.open('https://my.crhmoney.com', '_blank');
                    setMobileMenuOpen(false);
                  }}
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: '400',
                    whiteSpace: 'nowrap',
                    py: 1,
                  }}
                >
                  {t("HomeNav3", translations, language)}
                </MenuItem>

                <Divider sx={{ backgroundColor: '#FFFFFF', my: 1 }} />

                {/* Language Toggle */}
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                  <IconButton
                    onClick={() => {
                      switchLanguage(language === 'en' ? 'it' : 'en');
                      setMobileMenuOpen(false);
                    }}
                    sx={{ p: 1, color: '#FFFFFF' }}
                  >
                    {language === 'en' ? (
                      <img src="/images/Usa.svg" alt="English" style={{ width: '36px' }} />
                    ) : (
                      <img src="/images/Ita.svg" alt="Italian" style={{ width: '36px' }} />
                    )}
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>


        <Box>
          <Grid container>
            {/* Left side with picture */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: { xs: '2rem', sm: '2rem', md: '4rem', lg: '5rem' },
                pb: { xs: '2rem', sm: '2rem', md: '4rem', lg: '5rem' },
              }}
            >
              <Box
                sx={{
                  width: { xs: '80%', sm: '70%', md: '80%', lg: '80%' },
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src="/images/cubo.webp"
                  alt="CRH Money Cube"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    borderRadius: '8px',

                  }}
                />
              </Box>
            </Grid>

            {/* Right side with text */}
            
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                pr: { xs: '1rem', sm: '2rem', md: '2rem', lg: '2rem' },
                pl: { xs: '1rem', sm: '2rem', md: '4rem', lg: '6rem' },
                pt: { xs: '2rem', sm: '3rem', md: '4rem', lg: '0rem' },
                pb: { xs: '2rem', sm: '3rem', md: '4rem', lg: '10rem' },
              }}
            >
              <Box className="HomeBox1" sx={{ maxWidth: '530px' }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: { xs: '36px', sm: '42px', md: '48px', lg: '48px' },
                    color: '#FFFFFF',
                    mb: { xs: 2, sm: 3, md: 3, lg: 3 },
                    lineHeight: 1.2,
                  }}
                >
                  {t("Personal1", translations, language)}
                </Typography>

                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: { xs: '24px', sm: '30px', md: '36px', lg: '36px' },
                    color: '#FFFFFF',
                    mb: { xs: 3, sm: 4, md: 4, lg: 4 },
                  }}
                >
                  {t("Personal2", translations, language)}
                </Typography>


                <Typography
                  variant="h3"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    fontSize: { xs: '16px', sm: '20px', md: '24px', lg: '24px' },
                    color: '#FFFFFF',
                    mb: { xs: 4, sm: 5, md: 5, lg: 5 },
                    width: '100%',
                  }}
                >
                  {t("Personal3", translations, language)}
                </Typography>

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <Button
                    component="a"
                    onClick={handleApriIlConto}
                    variant="contained"
                    sx={{
                      backgroundColor: '#F7CE5F', // Rosso Savoia from color palette
                      color: '#000000',
                      borderRadius: '50px',
                      fontFamily: 'Poppins',
                      fontWeight: 500,
                      fontSize: { xs: '16px', sm: '16px', md: '20px', lg: '24px' },
                      padding: '10px 30px',
                      textTransform: 'uppercase',
                      '&:hover': { backgroundColor: '#9e2420' },

                    }}
                  >
                    {t("homeBox1_button", translations, language)}
                  </Button>
                </Box>
              </Box>
            </Grid>

          </Grid>
        </Box>


      </Box>
    </>
  );
};

export default Personal;