import React, { useEffect, useRef } from 'react';
import { Box, Typography, Container, } from '@mui/material';
import { useLanguage  } from '../LanguageContext';
import translations from '../translations';

const Contact_us = () => {
  const { language } = useLanguage ();
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        const formParams = language === 'en'
          ? {
              region: "eu1",
              portalId: "25343058",
              formId: "bdc0c0b2-8ae6-444b-bff4-413959274b3a",
              target: "#hubspotForm"
            }
          : {
              portalId: "25343058",
              formId: "0633558f-bfd7-4e30-b190-cbf7481e7333",
              target: "#hubspotForm"
            };

        window.hbspt.forms.create(formParams);
      }
    });
  }, [language]);


  return (
    <><Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        pt: { xs: '120px', sm: '150px', md: '280px', lg: '400px' },
        pb: { xs: '30px', sm: '50px', md: '80px', lg: '80px' },
      }}
    >
      <Container>
        <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
              color: '#ffffff',
              textAlign: 'center',
            }}
          >
            {translations[language]['Contatti']}
          </Typography>
        </Box>

        <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
              color: '#ffffff',
              textAlign: 'center',
            }}
          >
            {translations[language]["Il miglior modo di comunicare con noi è il seguente :"]}

          </Typography>
          <Box sx={{ mb: { xs: 4, sm: 6, md: 8, lg: 10 } }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                color: '#ffffff',
                textAlign: 'center',
                mb: 2,
              }}
            >
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li style={{ marginBottom: '1rem' }}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                      color: '#ffffff',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span style={{ marginRight: '0.5rem' }}>•</span>
                    {translations[language]["Via email : servizioclienti@crhmoney.com"]}
                  </Typography>
                </li>
                <li style={{ marginBottom: '1rem' }}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                      color: '#ffffff',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span style={{ marginRight: '0.5rem' }}>•</span>
                    {translations[language]["Via messaggi istantanei : tramite la chat istantanea che trovi in basso a destra."]}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
                      color: '#ffffff',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span style={{ marginRight: '0.5rem' }}>•</span>
                    {translations[language]["Via ticket compilando il modulo sottostante."]}
                  </Typography>
                </li>
              </ul>
            </Typography>
          </Box>


          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              pt: { xs: '8px', sm: '10px', md: '12px', lg: '15px' },
              pb: { xs: '8px', sm: '10px', md: '12px', lg: '15px' },
            }}
          >
            <Container>
              {/* Existing content */}
              <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '1.75rem', sm: '3rem', md: '3rem', lg: '3rem' },
              color: '#ffffff',
              textAlign: 'center',
            }}
          >
                {translations[language]['Contatti']}
              </Typography>
              {/* More existing content */}
              <div ref={formRef} id="hubspotForm"></div>
            </Container>
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '1.5rem' },
              color: '#ffffff',
              textAlign: 'center',
              mb: 2,
            }}
          >

            {translations[language]["I messaggi saranno automaticamente inviati alle persone più idonee a risponderti e risponderemo in massimo 48 ore"]}

          </Typography>


        </Box>
      </Container>
    </Box>

    </>
  );
};


export default Contact_us;