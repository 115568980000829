import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLanguage  } from '../LanguageContext';
import { t } from '../utils/translationUtils';
import translations from '../translations';

function Footer() {
    const { language } = useLanguage ();


    return (
        <Box
                sx={{
                    maxWidth: '1890px',
                    margin: '0 auto',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' },
                    fontWeight: 400,
                    textAlign: 'center',
                }}
            >
                
            
        <Box
            component="footer"
            sx={{
                position: 'relative',
                backgroundSize: {
                    xs: '100%',
                    sm: 'contain',
                    md: 'contain',
                },
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#000000',
                py: { xs: 32, sm: 25, md: 36 },
               
                color: '#FFFFFF',
                fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' },
                fontWeight: 400,
                width: '100%',
                textAlign: 'center',
                overflow: 'hidden',
                maxHeight: '300px',
            }}
        >
            <Grid
                container
                spacing={4}
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    pt: { xs: 2, sm: 4, md: 6 },
                    pl: { xs: 10, sm: 13, md: 16, lg: 20 },
                    pr: { xs: 2, sm: 4, md: 6 },
                }}
            >
                <Grid item xs={6} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        {t("FooterBox1", translations, language)}
                    </Typography>
                    <Link to="/about" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {t("FooterBox2", translations, language)}
                    </Link>
                    <Box onClick={() => window.open('https://www.casarealeholding.com/', '_blank')} style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                        <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' }, }}>
                            {t("FooterBox3", translations, language)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        {t("FooterBox4", translations, language)}
                    </Typography>
                    {language === 'it' ? (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252040-privacy-policy-crh-money-italiano', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Privacy
                            </Typography>
                        </Box>
                    ) : (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252047-privacy-policy-crh-money-english', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Privacy
                            </Typography>
                        </Box>
                    )}



                    {language === 'it' ? (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252038-crh-money-cookie-policy-italiano', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Cookie
                            </Typography>
                        </Box>
                    ) : (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252051-cookies-policy-crh-money-english', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Cookie
                            </Typography>
                        </Box>
                    )}

                    {language === 'it' ? (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252142-termini-e-condizioni-crh-money-italiano', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Termini e condizioni
                            </Typography>
                        </Box>
                    ) : (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252139-crh-money-term-and-conditions-english', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Terms and Conditions
                            </Typography>
                        </Box>
                    )}


                    {language === 'it' ? (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252142-termini-e-condizioni-crh-money-italiano', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Carte
                            </Typography>
                        </Box>
                    ) : (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252139-crh-money-term-and-conditions-english', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Cards
                            </Typography>
                        </Box>
                    )}


                    {language === 'it' ? (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252038-crh-money-cookie-policy-italiano', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Portafogli
                            </Typography>
                        </Box>
                    ) : (
                        <Box onClick={() => window.open('https://help.crhmoney.com/it/articles/10252051-cookies-policy-crh-money-english', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem', textAlign: 'left' } }}>
                                Wallets
                            </Typography>
                        </Box>
                    )}






                </Grid>
                <Grid item xs={6} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        {t("FooterBox16", translations, language)}
                    </Typography>
                    <Link to="/cards" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {t("FooterBox17", translations, language)}
                    </Link>
                    <Link to="/news" style={{ textDecoration: 'none', color: 'inherit', alignSelf: 'flex-start' }}>
                        {t("FooterBox17b", translations, language)}
                    </Link>
                </Grid>
                <Grid item xs={6} sm={3} display="flex" flexDirection="column" alignItems="flex-start">
                    <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        {t("FooterBox18", translations, language)}
                    </Typography>
                    <Link to="/cards" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {t("FooterBox19", translations, language)}
                    </Link>
                    <Link to="/assistance" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {t("FooterBox25", translations, language)}
                    </Link>
                    <Link to="/assistance" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {t("FooterBox24", translations, language)}
                    </Link>
                    {language === 'it' ? (
                        <Box onClick={() => window.open('https://guidautente.crhmoney.com/it/knowledge', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
                                Assistenza
                            </Typography>
                        </Box>
                    ) : (
                        <Box onClick={() => window.open('https://guidautente.crhmoney.com/en/knowledge', '_blank')}
                            style={{ cursor: 'pointer', marginBottom: 1, alignSelf: 'flex-start' }}>
                            <Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
                                Assistance
                            </Typography>
                        </Box>
                    )}
                    <Link to="/assistance" style={{ textDecoration: 'none', color: 'inherit', marginBottom: 1, alignSelf: 'flex-start' }}>
                        {t("FooterBox26", translations, language)}
                    </Link>

                </Grid>
            </Grid>


            <Box sx={{ mt: { xs: 4, sm: 5, md: 6, lg: 0 } }}>
                <Typography
                    variant="h3"
                    sx={{
                        fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem', lg: '1rem' },
                        color: '#ffffff',
                        textAlign: 'center',
                        lineHeight: 1.6,
                        padding: { xs: 4, sm: 8, md: 4, lg: 5 },

                    }}
                >
                    {t("FooterBox23", translations, language)}

                </Typography>
            </Box>
        </Box>
        </Box>
    );
}

export default Footer;